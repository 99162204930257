import { useMutation } from "@tanstack/vue-query";

export const useSaveDeviceMutation = () => {
  return useMutation({
    mutationFn: async (data: { deviceToken: string; deviceType: string }) => {
      const response = await useApi().auth["add-device"].$post({
        json: data
      });

      return response.json();
    }
  });
};
