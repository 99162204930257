import {
  type ActionPerformed,
  type PushNotificationSchema,
  PushNotifications,
  type Token,
} from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { useSaveDeviceMutation } from '~/domains/auth/mutations/useSaveDeviceMutation';
import { initializeApp, type FirebaseApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCmnyrgMBodRzoBAeQM3KQeELSo1YsY328",
  authDomain: "respostacerta-7fadb.firebaseapp.com",
  projectId: "respostacerta-7fadb",
  storageBucket: "respostacerta-7fadb.firebasestorage.app",
  messagingSenderId: "891378581243",
  appId: "1:891378581243:web:7e19f6f1bde30717a03a5e"
};

const hasBeenInitialized = ref(false)
const hasPermission = ref(false)
const canAskPermission = ref(true)

const firebaseApp = ref<FirebaseApp | null>(null)

export const usePushNotification = () => {
  const { mutate: saveDevice } = useSaveDeviceMutation()
  const isApp = useIsApp()

  const askPermissionApp = async () => {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    return new Promise((resolve, reject) => {
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();

          hasPermission.value = true

          resolve(true)
        } else {
          // Show some error
          reject(false)
        }
      });
    })
  }

  const initApp = async () => {
    if (hasBeenInitialized.value) return
    console.log('Initializing push');

    const permission = await PushNotifications.checkPermissions()

    if (permission.receive === 'granted') {
      hasPermission.value = true
    }

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        saveDevice({
          deviceToken: token.value,
          deviceType: Capacitor.getPlatform()
        })
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        console.error('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {

      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {

      }
    );

    hasBeenInitialized.value = true
  }

  const initWeb = async () => {
    try {
      const hasSupport = await isSupported();

      if (!hasSupport) {
        console.log('Push notifications not supported');
        canAskPermission.value = false
        return;
      }

      const app = initializeApp(firebaseConfig);
      firebaseApp.value = app

      const messaging = getMessaging(app);
    } catch (e) {
      console.error('Error on initialize push web', e);
      canAskPermission.value = false
      return;
    }

    console.log('Initializing push web')
    // Check if browser supports service worker / push notifications
    if (!('serviceWorker' in navigator)) {
      console.warn('Service worker not supported');
      canAskPermission.value = false
      return;
    }

    if (!('Notification' in window)) {
      console.warn('Notification not supported');
      canAskPermission.value = false
      return;
    }

    // Check the current notification permission status
    if (Notification.permission === 'granted') {
      hasPermission.value = true;
    } else if (Notification.permission === 'denied') {
      console.warn('Notification permission was denied');
      canAskPermission.value = false; // Can't ask again
    } else {
      // If permission is "default", we can still request it
      canAskPermission.value = true;
    }
  }

  const init = async () => {
    if (isApp && Capacitor.getPlatform() !== 'web') {
      await initApp()
    } else {
      await initWeb()
    }
  }

  const askPermissionWeb = async () => {
    if (!firebaseApp.value) {
      console.error('Firebase app not initialized');
      return;
    }

    const messaging = getMessaging(firebaseApp.value);
    try {
      const token = await getToken(messaging, {
        vapidKey: "BOIdhVDVlprvvQXO2MJoQcSpfzrBaxxOQxEOmSZcwlts4etzAar46hEuvaaSPNIA8LCIoDGfRxHdtE6FF23nc3Q"
      });

      saveDevice({
        deviceToken: token,
        deviceType: 'web'
      })

      hasPermission.value = true
      console.log('Token:', token);
    } catch (e) {
      console.log('Error on get token:', e);
      canAskPermission.value = false
    }
  }

  const askPermission = async () => {
    if (isApp) {
      await askPermissionApp()
    } else {
      // TODO: ask permission web
      await askPermissionWeb()
    }
  }

  return {
    askPermission,
    init,
    canAskPermission,
    hasPermission
  }
};